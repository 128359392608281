import { cn } from '@corify/helpers/cn';
import { ClassValue } from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';
import { Dialog as RACDialog, Heading, Modal, ModalOverlay } from 'react-aria-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../button/button';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  hideCloseButton?: boolean;
  variant?: 'default' | 'notification' | 'error';
  size?: 'sm' | 'md';
  id?: string;
  isHidden?: boolean;
}

export const Dialog = ({
  children,
  isOpen = false,
  onClose = () => {},
  title,
  variant = 'default',
  size,
  hideCloseButton = false,
  isHidden = false,
  id,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalOverlay
      onOpenChange={open => !open && onClose()}
      isOpen={isOpen}
      isDismissable
      className={cn(
        'fixed inset-0 z-[70] flex min-h-full items-center justify-center overflow-y-auto bg-black/75 p-4 data-[entering]:animate-[fadeIn_250ms] data-[exiting]:animate-[fadeIn_250ms_reverse]',
        {
          hidden: isHidden,
        }
      )}
    >
      <Modal>
        <RACDialog role="dialog" aria-label={!title ? 'dialog' : ''} className="outline-none">
          <div id={id} className="flex items-center justify-center p-4">
            <div
              className={cn(
                'relative w-full rounded-br-[80px] rounded-tl-[80px] border-b-4 border-r-4 bg-white px-11 py-8',
                {
                  'w-xl border-corifyGreen bg-gradient-to-r from-[#32005A] to-purple text-white':
                    variant === 'notification',
                  'w-[640px] border-purple': variant === 'default',
                  'w-[640px] border-corifyRed': variant === 'error',
                  'w-[480px]': size === 'sm',
                }
              )}
            >
              {variant === 'default' && typeof onClose === 'function' && !hideCloseButton && (
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <Button onClick={onClose} icon="close" variant="text" iconClassName="fill-purple">
                    <span className="sr-only">{t('close')}</span>
                  </Button>
                </div>
              )}

              {title && <Title className={cn({ 'text-white': variant === 'notification' })}>{title}</Title>}

              {children}
            </div>
          </div>
        </RACDialog>
      </Modal>
    </ModalOverlay>
  );
};

const Actions = ({ children, className }: PropsWithChildren & { className?: ClassValue }) => (
  <div className={cn('flex justify-center gap-8 pt-12', className)}>{children}</div>
);

interface ContentProps {
  children?: ReactNode;
  className?: string;
  id?: string;
}

const Content = ({ children, ...props }: ContentProps) => (
  <section {...props} className={cn('relative', props.className)}>
    {children}
  </section>
);

const Title = ({ children, className }: PropsWithChildren & { className?: ClassValue }) => (
  <Heading
    level={1}
    slot="title"
    className={cn(
      'flex flex-col items-center px-10 pb-8 text-center after:w-1/6 after:border-b-2 after:border-corifyGreen after:pt-2',
      className
    )}
  >
    {children}
  </Heading>
);

Dialog.Actions = Actions;
Dialog.Content = Content;
Dialog.Title = Title;
