import clsx from 'clsx';
import { OverlayArrow as OverlayArrowRAC } from 'react-aria-components';

type Type = 'defaultSmall' | 'defaultMedium' | 'tooltip' | 'contextMenu';
type ArrowParams = { path: string; size: number; viewBox: string; fill: string; style?: string };

const smallParams = { path: 'M0 0 L4 4 L8 0', size: 8, viewBox: '0 0 8 8', fill: 'white' };
const mediumParams = { path: 'M0 0 L6 6 L12 0', size: 12, viewBox: '0 0 12 12', fill: 'white' };

const arrowTypes: Record<Type, ArrowParams> = {
  defaultSmall: smallParams,
  defaultMedium: mediumParams,
  tooltip: { ...smallParams, fill: '#373737' },
  contextMenu: {
    ...mediumParams,
    style: '-translate-x-2.5 translate-y-0.5 stroke-lighterGrey',
  },
};

type Props = {
  type: Type;
};

export const OverlayArrow = ({ type }: Props) => {
  if (!type) {
    return null;
  }

  const { size, viewBox, path, style, fill } = arrowTypes[type];

  return (
    <OverlayArrowRAC>
      {({ placement }) => {
        return (
          <svg
            width={size}
            height={size}
            viewBox={viewBox}
            className={clsx('drop-shadow-popup', style, {
              'rotate-90': placement?.includes('right'),
              '-rotate-90': placement?.includes('left'),
              'rotate-180': placement?.includes('bottom'),
            })}
          >
            <path d={path} fill={fill} />
          </svg>
        );
      }}
    </OverlayArrowRAC>
  );
};
