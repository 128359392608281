import { Tooltip } from '@corify/components/tooltip/tooltip';
import { ReactElement, ReactNode } from 'react';

export type AdornmentProps = {
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  inputTooltip?: ReactNode;
  children: ReactElement;
};

export const Adornments = ({ adornmentLeft, adornmentRight, inputTooltip, children }: AdornmentProps) => {
  const content = (
    <div className="relative flex items-center justify-center gap-4">
      {children}
      {adornmentLeft && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-4 text-sm font-semibold text-darkGrey">
          {adornmentLeft}
        </div>
      )}
      {adornmentRight && (
        <div
          data-testid="adornment-right"
          className="absolute inset-y-0 right-0 flex items-center pr-4 text-sm font-semibold text-darkGrey"
        >
          {adornmentRight}
        </div>
      )}
    </div>
  );

  return inputTooltip ? (
    <Tooltip overlay={inputTooltip} delay={300}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
