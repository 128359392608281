import { Dropdown } from '@corify/components/inputs/dropdown/dropdown';
import { DropdownItem } from '@corify/components/inputs/search/types';
import { cn } from '@corify/helpers/cn';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GDVNotification } from '../types';
import { REASONS } from './constants';

type Props = GDVNotification & {
  readonly?: boolean;
  shouldHideLabel?: boolean;
  onChange?: (selectedItem?: string | null) => void;
  cfmV2?: boolean;
  customBgOnSelected?: string;
  hideExpandIcon?: boolean;
};

export type PauseReason = (typeof REASONS)[number];

export const PauseReasonSelect = ({
  pauseReason,
  readonly,
  shouldHideLabel = true,
  onChange,
  cfmV2,
  customBgOnSelected,
  hideExpandIcon,
}: Props) => {
  const { t } = useTranslation();

  const items: DropdownItem[] = useMemo(
    () =>
      REASONS.map(reason => ({ label: t(`fleetManagement.reasonsPausing.${reason}`), value: reason })).sort(
        (reason1, reason2) => reason1.label.localeCompare(reason2.label)
      ),
    []
  );

  const selectedReason = useMemo(() => {
    return items.find(item => item.value === pauseReason);
  }, [pauseReason, items]);

  const additionalStyle = () => {
    if (!cfmV2 || (!selectedReason?.value && !readonly)) {
      return readonly ? 'p-4 hover:border-purple bg-lighterGrey border-lighterGrey' : 'p-4';
    }

    if (customBgOnSelected) {
      return cn('p-4', customBgOnSelected);
    }

    if (readonly) {
      return 'bg-white p-4';
    }

    return 'p-4';
  };

  return (
    <Dropdown
      isClearable={cfmV2 ? !readonly : true}
      isRequired
      isReadOnly={readonly}
      label={t('fleetManagement.pauseDialog.dropdownLabel')}
      shouldHideLabel={shouldHideLabel}
      items={items}
      placeholder={t('components.dropdown.emptyOption')}
      onChange={onChange}
      value={selectedReason?.value}
      classNameInput={additionalStyle()}
      classNameLabel="min-h-1"
      shouldHideError={cfmV2}
      hideDefaultBorder={cfmV2}
      hideExpandIcon={hideExpandIcon}
    />
  );
};
