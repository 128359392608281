import { Avatar } from '../avatar/avatar';
import { Tooltip } from '../tooltip/tooltip';
import { AvatarUser } from '../user-avatar/avatar-user';
import { UserAvatar } from '../user-avatar/user-avatar';

interface Props<TUser extends AvatarUser> {
  users: TUser[];
  maxInlineDisplayedUsers: number;
  markAsFinishedPredicate?: (user: TUser) => boolean;
}

export const UserAvatarList = <TUser extends AvatarUser>({
  users,
  maxInlineDisplayedUsers,
  markAsFinishedPredicate,
}: Props<TUser>) => {
  const getFullName = (user: AvatarUser) => (typeof user === 'string' ? user : `${user.firstName} ${user.lastName}`);
  const getUserUiId = (user: AvatarUser) => (typeof user === 'string' ? user : user.email);

  const sortedUsers = users.sort((a, b) => getFullName(a).localeCompare(getFullName(b)));
  const shouldDisplayUsersInTooltip = users.length > maxInlineDisplayedUsers;
  const usersInline = shouldDisplayUsersInTooltip ? sortedUsers.slice(0, maxInlineDisplayedUsers - 1) : users;
  const usersInTooltip = shouldDisplayUsersInTooltip ? sortedUsers.slice(maxInlineDisplayedUsers - 1) : [];

  return (
    <div className="flex gap-2">
      {usersInline.map(user => (
        <UserAvatar
          key={getUserUiId(user)}
          user={user}
          shouldHideTooltip={false}
          shouldMarkAsFinished={markAsFinishedPredicate?.(user)}
        />
      ))}
      {shouldDisplayUsersInTooltip && (
        <div className="ml-2">
          <Tooltip
            overlay={
              <div data-testid="user-avatar-overlay" className="flex flex-col gap-4 p-2">
                {usersInTooltip.map(user => {
                  return (
                    <div className="flex items-center gap-2" key={getUserUiId(user)}>
                      <UserAvatar user={user} size="small" shouldMarkAsFinished={markAsFinishedPredicate?.(user)} />
                      <div className="text-black">
                        <h4 className="text-xs text-white">{getFullName(user)}</h4>
                        {typeof user !== 'string' && <span className="text-white">{user.email}</span>}
                      </div>
                    </div>
                  );
                })}
              </div>
            }
            position="top"
            delay={300}
            classNames={{ overlay: 'max-w-[400px]' }}
          >
            <div>
              <Avatar
                text={`+${usersInTooltip.length}`}
                size="medium"
                className="bg-purple hover:cursor-pointer"
                textClassName="text-white"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
