/* eslint-disable react/prop-types */
import { Icon } from '@corify/components/icon/icon';
import { Label } from '@corify/components/inputs/field-label/label';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { cn } from '@corify/helpers/cn';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import {
  Button as RACButton,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectStateContext,
  SelectValue,
} from 'react-aria-components';
import { useTranslation } from 'react-i18next';

export type DropdownItem = { label: string; value: string | null; key?: string };

interface Props {
  id?: string;
  label: string;
  shouldHideLabel?: boolean;
  shouldHideError?: boolean;
  value: string | undefined;
  items: DropdownItem[];
  isReadOnly?: boolean;
  error?: string | undefined;
  isRequired?: boolean;
  onChange?: (selectedItem?: string | null) => void;
  onBlur?: (selectedItem?: string | null) => void;
  width?: string;
  placeholder?: string;
  tooltip?: string;
  className?: string;
  classNameInput?: string;
  classNameInputText?: string;
  classNameLabel?: string;
  zIndex?: string;
  isClearable?: boolean;
  testId?: string;
  compact?: boolean;
  hideDefaultBorder?: boolean;
  hideExpandIcon?: boolean;
}

const SelectButton = forwardRef<HTMLButtonElement, Partial<Props> & { itemsWithKeys: DropdownItem[] }>(
  (
    {
      isReadOnly,
      isRequired,
      testId,
      error,
      classNameInput,
      isClearable,
      itemsWithKeys,
      placeholder,
      label,
      id,
      value,
      onBlur,
      compact,
      hideDefaultBorder,
      hideExpandIcon,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const state = useContext(SelectStateContext);
    const deleteIcon = isReadOnly ? 'clear_grey' : 'clear';
    const arrowIcon = state?.isOpen ? 'expand_less' : 'expand_more';
    const isDropdownErrorBorder = error && !state?.isOpen;
    const noItemDefined = itemsWithKeys?.length === 0;

    const innerComponentRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => innerComponentRef.current!);

    const displayValue = () => {
      if (noItemDefined) {
        return <div className="text-darkGrey">{t('components.dropdown.noOptionsText')}</div>;
      }

      if (state?.selectedItem) {
        const dropdownTooltip = itemsWithKeys?.find(item => item.value === value)?.label;
        return (
          <Tooltip overlay={dropdownTooltip}>
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
              <SelectValue />
            </div>
          </Tooltip>
        );
      }

      return <div className="text-darkGrey">{placeholder}</div>;
    };

    useEffect(() => {
      const newSelectedKey = itemsWithKeys?.find(item => item.value === value)?.key;

      if (newSelectedKey) {
        state?.setSelectedKey(newSelectedKey);
      }
    }, [itemsWithKeys, state, value]);

    return (
      <RACButton
        onBlur={() => {
          if (!isReadOnly && onBlur) {
            onBlur(itemsWithKeys?.find(item => !!item?.key && item.key === state.selectedKey)?.value);
          }
        }}
        ref={innerComponentRef}
        data-testid={kebabCase(testId || `dropdown-${label || id}`)}
        data-component="dropdown-field"
        className={cn(
          'flex w-full items-center justify-between',
          'text-left text-sm text-black',
          'h-[52px] border p-4 outline-none',
          {
            'border-purple': state?.isOpen,
            'border-corifyRed': isDropdownErrorBorder,
            'cursor-default': noItemDefined,
            'border-grey': !state?.isOpen && !error && !isReadOnly,
            'cursor-default border-lighterGrey bg-lighterGrey': isReadOnly,
            'w-full cursor-pointer bg-white hover:border-purple focus:border-purple': !isReadOnly,
            'bg-corifyFormError': isDropdownErrorBorder || (isRequired && !state?.selectedItem),
            'hover:bg-white': isRequired && !isReadOnly,
            'h-[40px] p-2': compact,
            'border-white': hideDefaultBorder && !error,
          },
          classNameInput
        )}
        id={id}
      >
        {displayValue()}
        <span className="flex flex-row items-center">
          {state?.selectedItem && isClearable && (
            <Icon
              name={deleteIcon}
              testId="clear-dropdown-selection"
              aria-label="clear value"
              className="z-[5] ml-3 flex h-[14px] w-[14px]"
              onClick={e => {
                if (!isReadOnly) {
                  e.stopPropagation();

                  state?.setSelectedKey(null);
                }
              }}
            />
          )}

          {!hideExpandIcon && (
            <Icon
              aria-label={arrowIcon}
              name={arrowIcon}
              className={clsx('ml-8 h-4 w-4 shrink-0', {
                'text-darkGrey group-hover:text-darkGrey': isReadOnly,
                'text-purple group-hover:text-purple': !isReadOnly,
                'ml-4': compact,
              })}
            />
          )}
        </span>
      </RACButton>
    );
  }
);

SelectButton.displayName = 'SelectButton';

export const Dropdown = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      label,
      shouldHideLabel = false,
      value,
      items,
      isRequired,
      tooltip,
      error,
      onBlur,
      onChange,
      isReadOnly,
      width,
      placeholder,
      className,
      classNameInput,
      classNameInputText,
      classNameLabel,
      isClearable = true,
      testId,
      shouldHideError,
      compact,
      hideDefaultBorder,
      hideExpandIcon,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const listBoxRef = useRef<HTMLDivElement | null>(null);

    const itemsWithKeys = useMemo(() => {
      if (!items) {
        return [];
      }

      return items.map((item, index) => {
        return { ...item, key: `react-aria-${index + 1}` };
      });
    }, [items]);

    const getPortalOptionsStyles = () => {
      if (!anchorRef || !anchorRef.current) {
        return;
      }
      const { width } = anchorRef.current?.getBoundingClientRect();

      return {
        width: `${width}px`,
      };
    };

    return (
      <div className="tooltip-selector">
        <Select
          {...props}
          defaultSelectedKey={itemsWithKeys?.find(item => item.value === value)?.key}
          onSelectionChange={selectedKey => {
            if (selectedKey === null) {
              onChange?.(undefined);
            }
            // for some reason using of custom keys is not working, so we have to rely on index
            if (listBoxRef.current) {
              const options = Object.values(listBoxRef.current.childNodes) as HTMLElement[];
              const keys = options.map(option => option.dataset.key);
              const newValue = items[keys.indexOf(selectedKey as string)]?.value;

              if (value !== newValue) {
                onChange?.(newValue);
              }
            }
          }}
          isDisabled={isReadOnly}
          ref={anchorRef}
          style={{ width }}
          className={cn('group relative', className)}
          aria-label={shouldHideLabel ? label : undefined}
        >
          {() => {
            return (
              <>
                {!shouldHideLabel && (
                  <Label
                    label={label || ''}
                    classes={{ root: 'flex mb-1 min-h-[2rem] w-full', label: 'body3' }}
                    isRequired={isRequired}
                    tooltip={tooltip}
                    tooltipTriggerSelectors={[{ isId: true, value: id ?? '' }, { value: '.tooltip-selector' }]}
                  />
                )}
                <SelectButton
                  isReadOnly={isReadOnly}
                  isRequired={isRequired}
                  error={error}
                  classNameInput={classNameInput}
                  isClearable={isClearable}
                  items={items}
                  placeholder={placeholder || t('components.dropdown.emptyOption')}
                  ref={ref}
                  testId={testId}
                  label={label}
                  id={id}
                  value={value}
                  itemsWithKeys={itemsWithKeys}
                  onBlur={onBlur}
                  compact={compact}
                  hideDefaultBorder={hideDefaultBorder}
                  hideExpandIcon={hideExpandIcon}
                />
                {!shouldHideError && <ErrorMessageContainer error={error} />}
                <Popover className="tooltip-selector">
                  <ListBox
                    ref={listBoxRef}
                    items={items}
                    className="mt-6 max-h-[350px] w-full overflow-auto rounded border bg-white py-2 shadow-[0_2px_4px_0] shadow-purpleLighter outline-none"
                    style={getPortalOptionsStyles()}
                  >
                    {items?.map((item, index) => {
                      return (
                        <ListBoxItem
                          key={`${item.value}${index}`}
                          textValue={item.label}
                          className={({ isSelected, isHovered, isFocused }) =>
                            clsx(
                              'body2 flex cursor-pointer justify-between p-4 text-left hover:bg-BG',
                              isSelected && 'bg-BG font-semibold text-purpleDarker',
                              isHovered || (isFocused && 'font-semibold')
                            )
                          }
                          data-testid={'dropdown-item-' + kebabCase(item.label)}
                        >
                          {({ isSelected }) => (
                            <>
                              {item.label}
                              {isSelected && <Icon name="check" className="ml-1 h-4 w-4 fill-purple" />}
                            </>
                          )}
                        </ListBoxItem>
                      );
                    })}
                  </ListBox>
                </Popover>
              </>
            );
          }}
        </Select>
      </div>
    );
  }
);

Dropdown.displayName = 'Dropdown';
