import { cn } from '@corify/helpers/cn';
import { ClassValue } from 'clsx';
import { forwardRef, ReactNode, Ref } from 'react';
import { Radio, RadioProps } from 'react-aria-components';

export interface Props extends RadioProps {
  label: string;
  adornmentRight?: ReactNode;
  classes?: {
    root?:
      | ClassValue
      | (({
          isDisabled,
          isFocused,
          isSelected,
          isRequired,
          isHovered,
        }: {
          isDisabled: boolean;
          isFocused: boolean;
          isSelected: boolean;
          isRequired: boolean;
          isHovered: boolean;
        }) => ClassValue);
    label?: ClassValue;
  };
}

export const RadioField = forwardRef(
  ({ label, classes, id, adornmentRight, ...props }: Props, ref: Ref<HTMLLabelElement> | undefined) => {
    return (
      <Radio
        id={id}
        data-tooltip-selector={id}
        ref={ref}
        className={({ isDisabled, isFocused, isSelected, isRequired, isHovered }) =>
          cn(
            'flex scroll-mt-[250px] items-start justify-start',
            'focus-within:border-purple',
            {
              'pointer-events-none cursor-default border-darkGrey': isDisabled,
              'cursor-pointer': !isDisabled,
            },
            typeof classes?.root === 'function'
              ? classes.root({ isDisabled, isFocused, isSelected, isRequired, isHovered })
              : classes?.root
          )
        }
        {...props}
      >
        {({ isSelected, isDisabled, isHovered }) => (
          <div className="flex w-full gap-4">
            <i
              className={cn('focus:gr form-radio block h-4 w-4 border-2 border-purple bg-purpleLighter', {
                'bg-purple ring-[3px] ring-inset ring-purpleLighter': isSelected || isHovered,
                'border-darkGrey bg-lighterGrey ring-lighterGrey': isDisabled,
                'bg-darkGrey': isDisabled && isSelected,
              })}
            />
            <p
              className={cn(
                'text-xs font-semibold text-darkGrey',
                !isDisabled ? 'cursor-pointer' : 'pointer-events-none cursor-default',
                classes?.label
              )}
            >
              {label}
            </p>
            {adornmentRight && (
              <>
                <div className="flex-grow"></div>
                {adornmentRight}
              </>
            )}
          </div>
        )}
      </Radio>
    );
  }
);

RadioField.displayName = 'RadioField';
