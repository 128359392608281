import { RadioFieldGroup } from '@corify/components/inputs/radio-group/radio-field-group';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GDVNotification } from '../types';
import { ApproveAutomaticallyPatch, useNotificationPatch } from './use-notification-patch';

type Props = { readonly?: boolean } & Pick<GDVNotification, 'approveAutomatically' | 'vehicleNotificationId'>;

const NotificationApproveRiskRadio = ({ approveAutomatically, vehicleNotificationId, readonly }: Props) => {
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const [automatically, setAutomatically] = useState(approveAutomatically);
  const { patchNotification } = useNotificationPatch<ApproveAutomaticallyPatch>(
    vehicleNotificationId,
    brokerId,
    'approveAutomatically'
  );

  const handleChange = async (checked: boolean) => {
    setAutomatically(checked);

    await patchNotification({ approveAutomatically: checked });
  };

  return (
    <RadioFieldGroup
      variant="no-style"
      value={automatically ? 'true' : 'false'}
      items={[
        {
          label: t('common.yes'),
          value: 'true',
        },
        {
          label: t('common.no'),
          value: 'false',
        },
      ]}
      onChange={value => handleChange(typeof value === 'string' ? value === 'true' : false)}
      label=""
      shouldHideLabel
      isDisabled={readonly}
      shouldHideError
      classes={{
        group: 'gap-8',
      }}
    />
  );
};

export default NotificationApproveRiskRadio;
