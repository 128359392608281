import { Label } from '@corify/components/inputs/field-label/label';
import { RadioField } from '@corify/components/inputs/radio-group/radio-field';
import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { cn } from '@corify/helpers/cn';
import { ClassValue } from 'clsx';
import { forwardRef, ReactNode, Ref } from 'react';
import { RadioGroup, RadioGroupProps } from 'react-aria-components';

export interface RadioFieldItem {
  label: string;
  value: string;
  adornmentRight?: ReactNode;
}

interface Props extends Omit<RadioGroupProps, 'children' | 'onChange' | 'onBlur'> {
  errorMessage?: string;
  onChange: (value: string | null | undefined) => void;
  onBlur?: (value: string | null | undefined) => void;
  items: RadioFieldItem[];
  label: string;
  shouldHideLabel?: boolean;
  shouldHideError?: boolean;
  tooltip?: string;
  id?: string;
  compact?: boolean;
  classes?: { root?: string; labelRoot?: ClassValue; radioFieldRoot?: string; label?: ClassValue; group?: ClassValue };
  variant?: 'default' | 'no-style';
}

export const RadioFieldGroup = forwardRef(
  (
    {
      errorMessage,
      items,
      label,
      shouldHideLabel = false,
      shouldHideError = false,
      isRequired,
      tooltip,
      value,
      id,
      compact,
      onBlur,
      classes,
      variant = 'default',
      ...props
    }: Props,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    return (
      <RadioGroup
        id={id}
        ref={ref}
        className={cn('flex flex-col', classes?.root)}
        onBlur={() => {
          onBlur?.(value);
        }}
        data-component="radio-field-group"
        value={typeof value === 'string' ? value : null}
        {...props}
      >
        {!shouldHideLabel && (
          <Label
            classes={{ root: cn('mb-1 min-h-[2rem] w-full', classes?.labelRoot), label: classes?.label ?? 'body3' }}
            label={label || ''}
            isRequired={isRequired}
            tooltip={tooltip}
            component="legend"
          />
        )}
        <div className={cn('group flex justify-between', classes?.group)}>
          {items.map((item, index) => (
            <RadioField
              value={item.value}
              aria-label={id ? `${id}-${item.label}` : item.label}
              classes={{
                root: ({ isDisabled, isHovered, isSelected, isFocused }) =>
                  cn(
                    'flex items-center',
                    variant === 'default' && [
                      'px-4 h-[52px] w-full outline-none bg-white',
                      'border border-purpleLighter ',
                    ],
                    variant === 'default' && {
                      'pl-2 h-[40px]': compact,
                      'bg-BG border-purple': isHovered || isFocused,
                      'bg-BG ': (isSelected || isFocused) && !isHovered,
                      'border-l-BG': value === item.value && index !== 0 && !isHovered && !isFocused,
                      'border-r-BG': value === item.value && index === 0 && !isHovered && !isFocused,
                      'relative z-1': (index !== 0 || index === 0) && (isHovered || isFocused),
                      '-ml-[1px]': index !== 0,
                      'bg-lighterGrey': isDisabled && isSelected,
                      'border-lighterGrey last:border-r-lighterGrey': isDisabled,
                      'border-corifyRed border-r-corifyFormError last:border-r-corifyRed': errorMessage,
                      'bg-corifyFormError': errorMessage || (isRequired && value === undefined),
                    },
                    classes?.radioFieldRoot
                  ),
              }}
              key={item.label}
              label={item.label}
              id={id ? `${id}-${item.label}` : item.label}
              adornmentRight={item.adornmentRight}
            />
          ))}
        </div>
        {!shouldHideError && <ErrorMessageContainer error={errorMessage} />}
      </RadioGroup>
    );
  }
);

RadioFieldGroup.displayName = 'RadioFieldGroup';
