import { Icon, IconName } from '@corify/components/icon/icon';
import { OverlayArrow } from '@corify/components/overlay-arrow/overlay-arrow';
import { distanceMap, popupClassnames } from '@corify/components/popup/popup';
import { Spinner } from '@corify/components/spinner/spinner';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { useRef, useState } from 'react';
import { Dialog, DialogTrigger, Popover } from 'react-aria-components';

import { Button, DefaultButtonProps } from '../button';

interface DropDownSection {
  icon: IconName;
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}

interface Props extends DefaultButtonProps {
  className?: string;
  iconClassName?: string;
  title?: string;
  message?: string;
  dropDownSections: DropDownSection[];
  isLoading?: boolean;
}

export const DropDownButton = ({ isDisabled, title, message, dropDownSections, isLoading }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleSectionClick = (item: DropDownSection, close: () => void) => {
    if (!item.isDisabled) {
      item.onClick();
      close();
    }
  };

  return (
    <div ref={ref}>
      <DialogTrigger onOpenChange={setOpen}>
        <Button
          data-testid={kebabCase(`dropdown-button-${title}`)}
          variant="primary"
          isDisabled={isDisabled || isLoading}
          className="relative m-0 p-0"
          onClick={() => setOpen(true)}
          size="small"
        >
          <span className="pl-4">{title}</span>
          <span className="h-[120%] w-[1px] bg-white"></span>
          {isLoading && <Spinner color="white" className="mr-2 h-4 w-4" />}
          {!isLoading && (
            <span className="pr-2">{open ? <Icon name="expand_less" /> : <Icon name="expand_more" />}</span>
          )}
        </Button>
        <Popover placement="bottom right" offset={distanceMap['medium']} triggerRef={ref}>
          <OverlayArrow type="contextMenu" />
          <Dialog className={popupClassnames}>
            {({ close }) => (
              <>
                {message && (
                  <span className="mb-1 flex max-w-64 flex-row gap-2 bg-BG px-2 py-3 text-xs">
                    <span className="h-[10px] w-[10px]">
                      <Icon name="alert" />
                    </span>
                    <span className="leading-4">{message}</span>
                  </span>
                )}
                {dropDownSections.map(item => (
                  <span
                    className={clsx('flex cursor-default flex-row items-center gap-3 px-2 py-4', {
                      'cursor-pointer hover:bg-BG': !item.isDisabled,
                    })}
                    key={item.title}
                    role="button"
                    data-testid={kebabCase(`dropdown-button-${item.title}`)}
                    tabIndex={0}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        handleSectionClick(item, close);
                      }
                    }}
                    onClick={() => handleSectionClick(item, close)}
                  >
                    {item.icon && (
                      <Icon
                        name={item.icon}
                        className={clsx('h-[20px] w-[20px] text-purple', { 'opacity-30': item.isDisabled })}
                      />
                    )}

                    <span className={clsx('whitespace-nowrap text-sm text-purple', { 'opacity-30': item.isDisabled })}>
                      {item.title}
                    </span>
                  </span>
                ))}
              </>
            )}
          </Dialog>
        </Popover>
      </DialogTrigger>
    </div>
  );
};
