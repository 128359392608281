import { FC, useState } from 'react';
import { Placement } from 'react-aria';

import { Icon } from '../icon/icon';
import { Tooltip } from './tooltip';

type Params = {
  tooltip: string;
  position?: Placement;
};

export const TooltipInfo: FC<Params> = ({ tooltip, position }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <Tooltip overlay={tooltip} position={position} onVisibleChange={setTooltipVisible} isOpen={tooltipVisible}>
      <span>
        <Icon name={tooltipVisible ? 'info_active' : 'info'} aria-label={tooltipVisible ? 'info active' : 'info'} />
      </span>
    </Tooltip>
  );
};
