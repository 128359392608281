import { Icon } from '@corify/components/icon/icon';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { endpoints } from '@corify/constants/endpoints';
import { useTranslation } from 'react-i18next';

import { NotificationId } from '../types';
import { useTriggerAction } from './use-trigger-action';

type Props = {
  type: 'pauseStop' | 'pause';
  notificationId: NotificationId;
  resetView?: () => void;
};

const NotificationPauseIcon = ({ type, notificationId, resetView }: Props) => {
  const { t } = useTranslation();

  const { handleAction } = useTriggerAction({
    endpoint: type === 'pause' ? endpoints.cfm.notifications.triggerPause : endpoints.cfm.notifications.triggerUnPause,
    payload: { vehicleNotificationIds: [notificationId] },
    resetView,
  });

  return (
    <Tooltip overlay={<div>{t('fleetManagement.stopPausing')}</div>} position="top">
      <span className="leading-none">
        <Icon className="h-[16px] w-[16px]" name={type} onClick={handleAction} />
      </span>
    </Tooltip>
  );
};

export default NotificationPauseIcon;
