import { Button } from '@corify/components/button/button';
import { Dialog } from '@corify/components/dialog/dialog';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { PauseReasonSelect } from '../common/pause-reason-select';
import { GDVNotification } from '../types';

type Props = {
  onDialogClose: (success?: boolean) => void;
  notifications: GDVNotification[];
};

export const PauseNotificationsDialog = ({ onDialogClose, notifications }: Props) => {
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const swrApiFetcher = useSwrApiFetcher();
  const [pauseReason, setPauseReason] = useState<string | null | undefined>(undefined);

  const { trigger, isMutating } = useSWRMutation(
    apiUrl(addUrlParameters(endpoints.cfm.notifications.triggerPause, { brokerId })),
    swrApiFetcher('POST')
  );

  const handleSave = useCallback(async () => {
    try {
      await trigger({
        payload: {
          vehicleNotificationIds: notifications.map(notification => notification.vehicleNotificationId),
          pauseReason,
        },
      });
      onDialogClose(true);
      toast(t('fleetManagement.pauseDialog.success', { count: notifications.length }), { type: 'success' });
    } catch {
      toast(t('fleetManagement.pauseDialog.error', { count: notifications.length }), { type: 'error' });
    }
  }, [trigger, notifications, pauseReason, t, onDialogClose]);

  return (
    <>
      <Dialog.Title className="text-xl font-bold">
        {(notifications.length || '') &&
          t('fleetManagement.pauseDialog.title', {
            count: notifications?.length,
            licensePlate: notifications?.[0]?.licensePlate,
          })}
      </Dialog.Title>

      <Dialog.Content>
        <PauseReasonSelect {...notifications[0]} shouldHideLabel={false} onChange={setPauseReason}></PauseReasonSelect>
      </Dialog.Content>

      <Dialog.Actions className="pt-2">
        <Button onClick={() => onDialogClose()} variant="secondary" size="small" isDisabled={isMutating}>
          {t('common.cancel')}
        </Button>
        <Button
          loading={isMutating}
          isDisabled={isMutating || !pauseReason}
          type="submit"
          variant="primary"
          size="small"
          testId="button-client-admin-send-invite"
          onClick={handleSave}
        >
          {t('common.save')}
        </Button>
      </Dialog.Actions>
    </>
  );
};
