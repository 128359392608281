import { Loader } from '@corify/components/loader/loader';
import { Filter } from '@corify/components/table/components/filter';
import { HeaderCell } from '@corify/components/table/components/header-cell';
import { Pagination } from '@corify/components/table/components/pagination/pagination';
import { PageProps } from '@corify/helpers/api/use-collection-fetch';
import { cn } from '@corify/helpers/cn';
import {
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  TableOptions,
  TableState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { isEqual } from 'lodash-es';
import { CSSProperties, Fragment, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getColumnPinningStyles, getCommonPinningClasses } from './helper';
import { useCorifyTable } from './provider/use-corify-table';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    preventLink?: boolean;
    classNames?: {
      bodyCell?: ClassValue;
      headerCell?: ClassValue;
    };
    styles?: {
      bodyCell?: CSSProperties;
      headerCell?: CSSProperties;
    };
    filterVariant?: 'text' | 'select' | 'date';
  }
}

type Props<TData> = Omit<TableOptions<TData>, 'getCoreRowModel' | 'getPaginationRowModel'> &
  PageProps & {
    classNames?: {
      expandedRow?: ClassValue;
      loader?: ClassValue;
      pagination?: ClassValue;
      root?: ClassValue;
      rowSelectedBg?: string;
      table?: ClassValue;
      tableBody?: string;
      tableBodyCell?: ClassValue;
      tableBodyRow?: (rowData: Row<TData>, index: number) => string | undefined;
      tableHead?: ClassValue;
      tableHeaderCell?: ClassValue;
      tableHeaderRow?: ClassValue;
    };
    rowLink?: (row: TData) => string;
    openLinkInNewTab?: boolean;
    align?: 'middle' | 'top';
    noDataMessage?: ReactNode | string;
    isLoading?: boolean;
    expandedRowContent?: (row: Row<TData>) => ReactNode;
    expandMode?: 'single' | 'multiple';
    enableTableSettingsCaching?: boolean;
    hideHeaderWhenEmpty?: boolean;
    useFilterHeader?: boolean;
    enableStickyHeader?: boolean;
    showStickyCellSeparatorAt?: number;
    oldDesign?: boolean;
    updateCachedState?: (value: SetStateAction<Partial<TableState>>) => void;
    onSortingChange?: (state: SortingState) => void;
  };

// eslint-disable-next-line react/function-component-definition
export function Table<TData>({
  classNames,
  rowLink,
  noDataMessage,
  align = 'middle',
  isLoading = false,
  expandMode = 'single',
  expandedRowContent,
  initialState,
  state,
  openLinkInNewTab,
  useFilterHeader,
  hideHeaderWhenEmpty = false,
  enableStickyHeader,
  showStickyCellSeparatorAt,
  oldDesign = true,
  updateCachedState,
  onSortingChange,
  ...props
}: Props<TData>) {
  const tableRef = useRef<HTMLTableElement | null>(null);
  const navigateTo = useNavigate();
  const [expanded, setExpanded] = useState<ExpandedState>(initialState?.expanded ?? {});
  const { pagination } = useCorifyTable<unknown, unknown>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const onExpandedChange = (tableSetExpanded: Updater<ExpandedState>) => {
    if (expandMode === 'multiple') {
      setExpanded(tableSetExpanded);
    } else {
      const newTableState = (tableSetExpanded as () => ExpandedState)();

      if (isEqual(newTableState, expanded)) {
        setExpanded({});
      } else {
        setExpanded(newTableState);
      }
    }
  };

  const table = useReactTable<TData>({
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    initialState: { ...initialState },

    onExpandedChange,
    onSortingChange: (state: Updater<SortingState>) => {
      setSorting(oldValue => {
        const newValue = (state as (v: SortingState) => SortingState)(oldValue);

        updateCachedState?.(oldSettings => ({
          ...oldSettings,
          sorting: newValue,
        }));

        onSortingChange?.(newValue);

        return newValue;
      });
    },
    autoResetPageIndex: false,
    state: {
      sorting,
      expanded,
      pagination,
      columnFilters,
      ...state,
    },
    onColumnFiltersChange: setColumnFilters,
    ...props,
  });

  const showTableHeaders = hideHeaderWhenEmpty ? !!table.getRowCount() : true;
  const selectedBg = classNames?.rowSelectedBg || '';

  useEffect(() => {
    tableRef?.current?.scrollIntoView({ block: 'start' });
  }, [pagination?.pageIndex]);

  return (
    <>
      <div
        id="table"
        className={cn('relative h-full overflow-x-auto', classNames?.root, {
          'overflow-hidden': isLoading,
        })}
      >
        {isLoading && (
          <div
            className={cn(
              'fixed left-0 top-0 z-10 flex h-full w-full items-center bg-white bg-opacity-70 align-middle',
              classNames?.loader
            )}
          >
            <Loader />
          </div>
        )}
        <table className={cn('relative w-full table-auto', classNames?.table)} ref={tableRef}>
          <thead
            id="table-data-list-header"
            className={cn(classNames?.tableHead, {
              'sticky top-0 z-[9] bg-white shadow-[0_2px_4px_0] shadow-purpleLighter': enableStickyHeader,
            })}
          >
            {showTableHeaders &&
              table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className={cn('text-left', classNames?.tableHeaderRow)}>
                  {showTableHeaders &&
                    headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cn([
                          'body3 border-greyLight h-[55px] border-b bg-white px-6 py-2 align-middle text-darkGrey',
                          {
                            'border-r border-r-lightGrey':
                              index < headerGroup.headers.length - 1 && !header.column.getIsPinned(),
                            'font-semibold uppercase': oldDesign,
                          },
                          getCommonPinningClasses(
                            header.column,
                            index < headerGroup.headers.length - 1,
                            oldDesign,
                            showStickyCellSeparatorAt
                          ),
                          classNames?.tableHeaderCell,
                          header.column.columnDef.meta?.classNames?.headerCell,
                        ])}
                        style={{
                          minWidth: header.column.getSize(),
                          ...header.column.columnDef.meta?.styles?.headerCell,
                          ...getColumnPinningStyles(header.column),
                        }}
                      >
                        {!useFilterHeader &&
                          (header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext()))}
                        {useFilterHeader && (
                          <HeaderCell
                            topContent={
                              header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())
                            }
                            iconName={
                              ({
                                asc: 'ascending',
                                desc: 'descending',
                              }[header.column.getIsSorted() as string] as 'ascending' | 'descending') ?? undefined
                            }
                            onTopContentClick={
                              header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined
                            }
                            bottomContent={header.column.getCanFilter() ? <Filter column={header.column} /> : null}
                          />
                        )}
                      </th>
                    ))}
                </tr>
              ))}
          </thead>

          <tbody className={classNames?.tableBody}>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <Fragment key={row.id}>
                <tr
                  className={cn(
                    'body2 relative table-row w-full align-middle',
                    {
                      'select-none hover:bg-BG': rowLink,
                      'border-b border-b-lightGrey last:border-b-white': oldDesign,
                      // This is a hack, the sticky class, is sometimes buggy in some browser engines
                      'after:absolute after:left-0 after:top-0 after:z-[8] after:h-[1px] after:w-full after:bg-lightGrey after:content-[""]':
                        !oldDesign,
                    },
                    classNames?.tableBodyRow?.(row, rowIndex)
                  )}
                  data-testid={`table-row-${rowIndex}`}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'relative table-cell px-6 py-4',
                        {
                          'cursor-pointer': rowLink && cell.column.columnDef.meta?.preventLink !== true,
                          'align-top': align === 'top',
                          'align-middle': align === 'middle',
                          [selectedBg]: row.getIsSelected(),
                          'border-r border-r-lightGrey':
                            index < row.getVisibleCells().length - 1 && !cell.column.getIsPinned(),
                        },
                        getCommonPinningClasses(
                          cell.column,
                          index < row.getVisibleCells().length - 1,
                          oldDesign,
                          showStickyCellSeparatorAt
                        ),
                        classNames?.tableBodyCell,
                        cell.column.columnDef.meta?.classNames?.bodyCell
                      )}
                      style={{
                        minWidth: cell.column.getSize(),
                        ...cell.column.columnDef.meta?.styles?.bodyCell,
                        ...getColumnPinningStyles(cell.column),
                      }}
                      onClick={() => {
                        if (rowLink && cell.column.columnDef.meta?.preventLink !== true) {
                          if (openLinkInNewTab) {
                            window.open(rowLink(row.original) || '#', '_blank');
                          } else {
                            navigateTo(rowLink(row.original) || '#');
                          }
                        }
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr
                    className={cn(
                      'body2 table-row w-full border-t border-lightGrey align-middle',
                      classNames?.expandedRow
                    )}
                  >
                    <td className="relative table-cell w-full" colSpan={row.getVisibleCells().length}>
                      {expandedRowContent?.(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
        {table.getRowCount() === 0 && noDataMessage && (
          <div className="flex h-[calc(100%-72px)] items-center justify-center">{noDataMessage}</div>
        )}
      </div>
      <Pagination
        table={table}
        className={clsx(
          {
            '-ml-4': oldDesign,
          },
          classNames?.pagination
        )}
      />
    </>
  );
}
