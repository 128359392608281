import { Icon } from '@corify/components/icon/icon';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { cn } from '@corify/helpers/cn';
import { ClassValue } from 'clsx';
import {
  ElementType,
  FC,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { AriaFieldProps } from 'react-aria';
import { Label as AriaLabel, LabelContext, useContextProps } from 'react-aria-components';

import { TooltipTriggerSelector, useHelpMode } from '../../help-mode-switch/use-help-mode';

interface Props extends AriaFieldProps {
  classes?: { label?: ClassValue; root?: ClassValue; tooltip?: ClassValue; required?: ClassValue };
  htmlFor?: string;
  label: string;
  isRequired?: boolean;
  tooltip?: string;
  component?: ElementType;
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  forceTooltipVisible?: boolean;
  tooltipTriggerSelectors?: TooltipTriggerSelector[];
}

export const Label: FC<PropsWithChildren<Props>> = forwardRef<HTMLLabelElement, PropsWithChildren<Props>>(
  (
    {
      classes,
      label,
      isRequired,
      tooltip,
      children,
      adornmentRight,
      adornmentLeft,
      forceTooltipVisible = false,
      tooltipTriggerSelectors = [],
      ...props
    },
    ref
  ) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [customProps, labelRef] = useContextProps(props, ref, LabelContext);

    const { shouldTriggerTooltip } = useHelpMode({
      containerElement: labelRef.current?.parentElement,
      triggerSelectors: tooltipTriggerSelectors.concat(
        customProps.id ? [{ isId: true, value: customProps.id }] : [],
        customProps.htmlFor ? [{ isId: true, value: customProps.htmlFor }] : []
      ),
    });

    useImperativeHandle(ref, () => labelRef.current as HTMLLabelElement);

    const isTooltipVisible = forceTooltipVisible ? true : shouldTriggerTooltip || tooltipVisible;

    const iconWithTooltip = useMemo(
      () =>
        tooltip ? (
          <Tooltip overlay={tooltip} onVisibleChange={setTooltipVisible} isOpen={isTooltipVisible || false}>
            <div className={cn('ml-auto h-4 w-4', classes?.tooltip)}>
              <Icon
                name={isTooltipVisible ? 'info_active' : 'info'}
                aria-label={isTooltipVisible ? 'info active' : 'info'}
                className="-mt-2 fill-purple"
              />
            </div>
          </Tooltip>
        ) : null,
      [classes?.tooltip, isTooltipVisible, tooltip]
    );

    return (
      <AriaLabel
        {...props}
        ref={labelRef}
        className={cn('flex items-start justify-between', classes?.root)}
        data-component="label"
      >
        {adornmentLeft}
        <div className="flex items-start">
          <span className={cn('text-xs font-semibold text-darkGrey', classes?.label)}>{label}</span>
          {isRequired && <div className={cn('mx-1 -mt-1 font-semibold text-corifyRed', classes?.required)}>*</div>}
        </div>
        {children}
        {adornmentRight}
        {iconWithTooltip}
      </AriaLabel>
    );
  }
);

Label.displayName = 'Label';
