import { cn } from '@corify/helpers/cn';
import { cloneElement, ReactElement, ReactNode, useRef } from 'react';
import { mergeProps, Placement, useButton } from 'react-aria';
import { Tooltip as TooltipRAC, TooltipProps, TooltipTrigger } from 'react-aria-components';

import { OverlayArrow } from '../overlay-arrow/overlay-arrow';
import { MarkdownOverlay } from './markdown-overlay/markdown-overlay';

interface Props extends TooltipProps {
  overlay: ReactNode | string;
  children: ReactElement<HTMLDivElement | HTMLSpanElement | HTMLButtonElement>;
  onVisibleChange?: (visible: boolean) => void;
  position?: Placement;
  delay?: number;
  isOpen?: boolean;
  classNames?: { overlay?: string };
}

function TriggerWrapper(props) {
  const triggerRef = useRef(null);
  const { buttonProps } = useButton(props, triggerRef);

  return cloneElement(props.children, mergeProps(buttonProps, props.children.props, { ref: triggerRef }));
}

export const Tooltip = ({ children, overlay, position, delay = 0, isOpen, classNames, onVisibleChange }: Props) => {
  const shouldUseMarkdownOverlay = typeof overlay === 'string';

  if (!overlay) {
    return children;
  }

  return (
    <TooltipTrigger delay={delay} closeDelay={0} isOpen={isOpen} onOpenChange={onVisibleChange}>
      <TriggerWrapper>{children}</TriggerWrapper>
      <TooltipRAC
        className={cn(
          'max-w-[300px] rounded bg-[#373737] p-3 text-xs text-white drop-shadow-popup',
          classNames?.overlay
        )}
        offset={10}
        placement={position}
      >
        <OverlayArrow type="tooltip" />
        {shouldUseMarkdownOverlay ? <MarkdownOverlay markdownContent={overlay} /> : overlay}
      </TooltipRAC>
    </TooltipTrigger>
  );
};
