import { ClientType } from '@corify/types/client';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Avatar } from '../avatar/avatar';
import { Icon } from '../icon/icon';
import { Tooltip } from '../tooltip/tooltip';
import { AvatarUser } from './avatar-user';
import { getAvatarColor } from './get-avatar-color';
import { getInitials } from './get-initials';

interface Props {
  user: AvatarUser;
  size?: 'small' | 'medium' | 'big';
  shouldMarkAsFinished?: boolean;
  shouldHideTooltip?: boolean;
  clientType?: ClientType;
}

export const UserAvatar = ({
  user,
  size = 'medium',
  shouldMarkAsFinished = false,
  shouldHideTooltip = true,
  clientType,
}: Props) => {
  const isUserWithOnlyName = typeof user === 'string';
  const { backgroundStyle, textStyle } = getAvatarColor(user, clientType);
  const fullName = isUserWithOnlyName ? user : `${user.firstName} ${user.lastName}`;

  const avatarNode = (
    <Avatar
      text={getInitials(fullName)}
      size={size}
      data-testid="avatar"
      className={clsx(backgroundStyle, 'hover:cursor-default')}
      textClassName={textStyle}
    >
      {shouldMarkAsFinished && (
        <Icon
          name="positive"
          className={twMerge('float-right h-2.5 w-2.5', size === 'small' && 'h-2 w-2')}
          aria-label="finished work"
        />
      )}
    </Avatar>
  );

  if (shouldHideTooltip) {
    return avatarNode;
  }

  return (
    <Tooltip
      delay={300}
      overlay={
        <div className="text-center">
          <span className="block text-xs font-bold">{fullName}</span>
          {!isUserWithOnlyName && <span>{user.email}</span>}
        </div>
      }
      classNames={{ overlay: 'max-w-[400px]' }}
    >
      <div>{avatarNode}</div>
    </Tooltip>
  );
};
