import type { Location } from '@remix-run/router';
import { matchRoutes } from 'react-router-dom';

export function assertUrlParameter<T, TName = keyof T>(
  param: string | undefined,
  name: TName
): asserts param is string {
  if (!param) {
    throw new Error(`There is a missing ${name} property`);
  }
}

export type ParseUrlParams<url extends string> = url extends `${infer left}/${infer right}`
  ? ParseUrlParams<left> & ParseUrlParams<right>
  : url extends `:${infer param}`
    ? { [k in param]: string }
    : object;

export const addUrlParameters = <T extends string>(url: T, params: ParseUrlParams<T>) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, value as string) as T;
  }, url);
};

export const isLocationMatch = (location: Location, routesToMatch: { [key: string]: string }) => {
  const paths = Object.values(routesToMatch).map(path => ({ path }));

  return (matchRoutes(paths, location)?.length ?? 0) > 0;
};

export const appendSearchParams = (inputUrl: string, record: Record<string, string | undefined>) => {
  const url = new URL(inputUrl, 'http://dummy.domain.com');
  Object.entries(record).forEach(([key, value]) => value && url.searchParams.set(key, value));
  return `${url.pathname}${url.search}`;
};
