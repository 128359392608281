import 'react-date-range/dist/styles.css';
import './date-picker.css';

import { Button } from '@corify/components/button/button';
import { Icon } from '@corify/components/icon/icon';
import { Label } from '@corify/components/inputs/field-label/label';
import { distanceMap, popupClassnames } from '@corify/components/popup/popup';
import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { cn } from '@corify/helpers/cn';
import {
  Calendar as CalenderType,
  CalendarDate,
  getLocalTimeZone,
  GregorianCalendar,
  parseDate,
} from '@internationalized/date';
import { ValidationResult } from '@react-types/shared';
import clsx, { ClassValue } from 'clsx';
import { isAfter, isBefore } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDateField } from 'react-aria';
import { Placement } from 'react-aria';
import { Popover } from 'react-aria-components';
import { Calendar } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { useDateFieldState } from 'react-stately';

import { dateToStringDate, stringDateToDate } from '../../json-form/corify-theme/helpers';
import { DateSegment } from './date-segment';
import { Navigator } from './navigator/navigator';

const createCalendar = (): CalenderType => {
  return new GregorianCalendar();
};

interface Props {
  classNamePopup?: string;
  classNames?: { icon?: ClassValue; wrapper?: ClassValue; root?: ClassValue };
  disableKeyboard?: boolean;
  error?: string;
  formatMaximum?: string;
  formatMinimum?: string;
  id?: string;
  isReadonly?: boolean;
  label: string;
  onBlur?: (value?: string) => void;
  onChange: (value: string | undefined) => void;
  onValidation?: (validation: ValidationResult) => void;
  popupPlacement?: Placement;
  required?: boolean;
  shouldHideErrorPlaceholder?: boolean;
  shouldHideLabel?: boolean;
  tooltip?: string;
  value: string;
  textSize?: 'sm' | 'xs';
  compact?: boolean;
}

const outputFormat = 'yyyy-MM-dd';

export const DatePicker = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      isReadonly,
      error,
      id,
      label,
      shouldHideLabel = false,
      onChange,
      required,
      tooltip,
      onBlur,
      value,
      formatMinimum,
      formatMaximum,
      shouldHideErrorPlaceholder = false,
      popupPlacement = 'bottom end',
      disableKeyboard = false,
      classNames,
      onValidation,
      compact,
      textSize,
      ...props
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const isTodaySmallerOrEqualThanMaximum = formatMaximum && new Date(formatMaximum) >= new Date();

    const [isFocused, setIsFocused] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [ignoreAdjustedFocusDate, setIgnoreAdjustedFocusDate] = useState(false);
    const [calendarDate, setCalendarDate] = useState<CalendarDate | null>(value ? parseDate(value) : null);
    const referenceElement = useRef(null);
    // @see https://github.com/rjsf-team/react-jsonschema-form/issues/2718 currently not possible to throw a error from a component
    const [isInvalid, setIsInvalid] = useState(false);

    const state = useDateFieldState({
      ...props,
      label,
      value: calendarDate,
      locale: i18n.language === 'en' ? 'en-GB' : 'de-DE',
      isReadOnly: isReadonly,
      isRequired: required,
      isDisabled: isReadonly,
      minValue: formatMinimum ? parseDate(formatMinimum) : undefined,
      maxValue: formatMaximum ? parseDate(formatMaximum) : undefined,
      createCalendar,
      onChange: value => {
        if (value && value.year && value.month && value.day) {
          const cDate = new CalendarDate(value.year, value.month, value.day);

          setCalendarDate(cDate);

          onChange(dateToStringDate(cDate.toDate(getLocalTimeZone()), outputFormat));
        } else {
          setCalendarDate(null);
          onChange(undefined);
        }

        setIsInvalid(false);
      },
    });

    const innerRef = useRef<HTMLDivElement | null>(null);

    const handleBlur = () => {
      if (required) {
        setIsInvalid(calendarDate === null);
      }

      setIsFocused(false);

      onBlur?.(calendarDate ? dateToStringDate(state.dateValue, outputFormat) : undefined);
    };

    const { labelProps, fieldProps } = useDateField(
      {
        ...props,
        label,
        isReadOnly: isReadonly,
        isRequired: required,
        onFocus: () => setIsFocused(true),
        onBlur: handleBlur,
      },
      state,
      innerRef
    );

    const handleClose = () => {
      setIsOpen(false);

      handleBlur();
    };

    const handleOpen = () => {
      setIsOpen(!isOpen);
      innerRef.current?.focus();
    };

    useEffect(() => {
      setCalendarDate(value ? parseDate(value) : null);
    }, [value]);

    useEffect(() => {
      if (!isOpen && !value) {
        setIgnoreAdjustedFocusDate(false);
      }
    }, [isOpen, value]);

    useEffect(() => {
      setIsInvalid(state.displayValidation.isInvalid);

      onValidation?.(state.displayValidation);
    }, [onValidation, state.displayValidation]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => innerRef.current);

    return (
      <>
        <div
          data-testid="datepicker"
          ref={referenceElement}
          data-component="datepicker"
          className={clsx(classNames?.root)}
        >
          {!shouldHideLabel && (
            <Label
              {...labelProps}
              htmlFor={id}
              label={label}
              isRequired={required}
              tooltip={tooltip}
              classes={{ root: 'flex mb-1 min-h-[2rem] w-full', label: labelProps.className }}
              tooltipTriggerSelectors={[{ value: '.datepicker-segment' }]}
            />
          )}
          <div className="relative">
            <div
              {...fieldProps}
              ref={innerRef}
              id={id}
              data-testid="datepicker-wrapper"
              className={cn(
                'flex h-[52px] w-full items-center bg-white py-3 pl-4 pr-16 text-right text-sm text-black placeholder:text-darkGrey',
                'border-grey pr-16',
                {
                  'h-[40px] pl-2': compact,
                  'border-lighterGrey bg-lighterGrey': isReadonly,
                  'border hover:border-purple focus:border-purple': !isReadonly,
                  'border-corifyRed': isInvalid || error,
                  'bg-corifyFormError focus:bg-white': !isReadonly && (isInvalid || error || (required && !value)),
                  'hover:bg-white': required && !isReadonly,
                  'focus: border-purple': isFocused,
                },
                classNames?.wrapper
              )}
            >
              {state.segments.map((segment, i) => (
                <DateSegment
                  key={i}
                  segment={segment}
                  state={state}
                  id={id}
                  disableKeyboard={disableKeyboard}
                  textSize={textSize}
                />
              ))}
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 text-sm font-semibold text-darkGrey">
              <div className={clsx('flex', textSize === 'xs' ? 'space-x-2' : 'space-x-4')}>
                {value && !isReadonly && (
                  <Icon
                    aria-label="Clear date"
                    name="clear"
                    className={cn('h-4 w-4', classNames?.icon)}
                    onClick={() => {
                      setCalendarDate(null);
                      setIsOpen(false);
                      onChange(undefined);
                      setIgnoreAdjustedFocusDate(false);

                      if (required) {
                        setIsInvalid(true);
                      }
                    }}
                  />
                )}
                <Icon
                  aria-label={`${t('components.datePicker.label') ?? ''}`}
                  className={cn(
                    isReadonly ? 'text-darkGrey' : 'cursor-pointer text-purple',
                    'z-[5] h-4 w-4 outline-none',
                    classNames?.icon
                  )}
                  name="calender"
                  tabIndex={isReadonly ? -1 : 0}
                  onKeyDown={
                    !isReadonly
                      ? e => {
                          if (e.key === 'Enter') {
                            handleOpen();
                          }
                        }
                      : undefined
                  }
                  onClick={!isReadonly ? handleOpen : undefined}
                />
              </div>
            </div>
          </div>
          {shouldHideErrorPlaceholder ? null : <ErrorMessageContainer error={error} />}
        </div>
        <Popover
          triggerRef={referenceElement}
          isOpen={isOpen}
          onOpenChange={value => !value && handleClose()}
          offset={distanceMap['small']}
          placement={popupPlacement}
          className={popupClassnames}
        >
          <div className="w-[345px] pb-4">
            <Calendar
              navigatorRenderer={(currFocusedDate, changeShownDate, props) => {
                let adjustedFocusDate = currFocusedDate;

                if (!ignoreAdjustedFocusDate && !value) {
                  const isTodayAfterMinDate = formatMinimum ? isAfter(new Date(), new Date(formatMinimum)) : true;
                  const isTodayBeforeMaxDate = formatMaximum ? isBefore(new Date(), new Date(formatMaximum)) : true;

                  if (!isTodayAfterMinDate && isTodayBeforeMaxDate && formatMinimum) {
                    adjustedFocusDate = new Date(formatMinimum);
                  }

                  if (isTodayAfterMinDate && !isTodayBeforeMaxDate && formatMaximum) {
                    adjustedFocusDate = new Date(formatMaximum);
                  }

                  setIgnoreAdjustedFocusDate(true);
                }

                return (
                  <Navigator
                    currFocusedDate={adjustedFocusDate}
                    changeShownDate={changeShownDate}
                    calendarProps={props}
                    formatMinimum={formatMinimum}
                    formatMaximum={formatMaximum}
                    id={id}
                  />
                );
              }}
              weekStartsOn={1}
              showMonthArrow={false}
              minDate={formatMinimum ? parseDate(formatMinimum)?.toDate(getLocalTimeZone()) : undefined}
              maxDate={formatMaximum ? parseDate(formatMaximum)?.toDate(getLocalTimeZone()) : undefined}
              onChange={date => {
                const transformedDate = dateToStringDate(date, outputFormat);

                if (transformedDate !== value) {
                  setCalendarDate(parseDate(transformedDate));
                  setIsInvalid(false);

                  onChange(transformedDate);
                }

                setIsOpen(false);
              }}
              locale={i18n.language === 'en' ? enGB : de}
              date={stringDateToDate(value, outputFormat) || undefined}
              className="w-full"
            />
            {(!formatMaximum || isTodaySmallerOrEqualThanMaximum) && (
              <div className="flex justify-end pr-7">
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    const transformedDate = dateToStringDate(new Date(), outputFormat);

                    if (transformedDate !== value) {
                      setCalendarDate(parseDate(transformedDate));
                      setIsInvalid(false);

                      onChange(transformedDate);
                    }

                    setIsOpen(false);
                  }}
                >
                  {t('components.datePicker.today')}
                </Button>
              </div>
            )}
          </div>
        </Popover>
      </>
    );
  }
);

DatePicker.displayName = 'DatePicker';
